import axios from "axios";

export const STORE_STATION_AREAS = "STORE_STATION_AREAS";
export const SELECT_AREA = "SELECT_AREA";
export const STORE_STATIONS = "STORE_STATIONS";
export const SELECT_STATION = "SELECT_STATION";
export const STORE_STATION = "STORE_STATION";
export const IS_LOADING = "IS_LOADING";
export const FILTER_LIST = "FILTER_LIST";

export const stationAreas = "StationAreas";
export const stations = "Stations";

export const isLoading = isLoading => ({ type: IS_LOADING, isLoading });
export const selectArea = stationArea => ({ type: SELECT_AREA, stationArea });
export const storeStations = stations => ({ type: STORE_STATIONS, stations });
export const storeStation = station => ({ type: STORE_STATION, station });
export const filterList = items => ({ type: FILTER_LIST, items });
export const storeStationAreas = stationAreas => ({
  type: STORE_STATION_AREAS,
  stationAreas
});
export const storeSelectedStation = selectedStation => ({
  type: SELECT_STATION,
  selectedStation
});

export function fetchStationAreas(vendorId) {
  return dispatch => {
    dispatch(isLoading(true));

    if (vendorId && vendorId !== "ALL") {
      fetchVendorStationAreas(dispatch, vendorId);
    } else {
      fetchAllStationAreas(dispatch);
    }
  };
}


export function fetchStations() {
  return dispatch => {
    dispatch(isLoading(true));

    axios
      .get("/Charging/Stations")
      .then(res => {
        dispatch(storeStations(res.data.stations));
        dispatch(isLoading(false));
      })
      .catch(err => console.log(err));
  };
}

export function fetchStation(stationId) {
  return dispatch => {
    dispatch(isLoading(true));

    if (stationId) {
      axios
        .post("/Charging/Station", {
          stationId
        })
        .then(res => {
          dispatch(storeStation(res.data));
          dispatch(storeSelectedStation(res.data));
          dispatch(isLoading(false));
        })
        .catch(err => console.log(err));
    }
  };
}

function fetchVendorStationAreas(dispatch, vendorId) {
  axios
    .post("/Charging/VendorStationAreas", {
      vendorId
    })
    .then(res => {
      dispatch(storeStationAreas(res.data.stationAreas));
      dispatch(isLoading(false));
    })
    .catch(err => console.log(err));
}

function fetchAllStationAreas(dispatch) {
  axios
    .get("/Charging/StationAreas")
      .then(res => {
        dispatch(storeStationAreas(res.data.stationAreas));
        dispatch(isLoading(false));
      })
      .catch(err => console.log(err));
}
