import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import StationsAreaListItem from "./StationsAreaListItem";
import SearchFilter from "../shared/SearchFilter";
import Paper from "@material-ui/core/Paper";
import Spinner from "../shared/Spinner";
import { getDistanceBetweenTwoCoordinates } from "../../utils/helpers";

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
    color: "#FFF",
    fontFamily: 'Montserrat'
  },
  paper: {
    backgroundColor: theme.palette.primary.light,
    marginBottom: "10px"
  },
  container: {
    backgroundColor: theme.palette.primary.main
  }
});

class StationsList extends React.Component {
  state = {
    filteredList: [],
    currentCoordinates: [61.92410999999999, 25.748151],
  };

  getCurrentCoordinates() {
    navigator.geolocation.getCurrentPosition(
      position => {
        this.setState({
          currentCoordinates: [
            position.coords.latitude,
            position.coords.longitude,
          ]
        });
      },
      error => console.log(error),
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
    );
  }

  getDistance(latitude, longitude) {
    return (
      Number(
        getDistanceBetweenTwoCoordinates(
          this.state.currentCoordinates[0],
          this.state.currentCoordinates[1],
          latitude,
          longitude
        ).toFixed(0)
      )
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.stationAreas !== this.props.stationAreas) {
      this.getCurrentCoordinates();
      const fl = this.props.stationAreas.filter(s => {
        if (s.name) {
          return Object.keys(s.stations).length > 0;
        } 
        return [];
      });
      this.setState({ filteredList: fl });
    }
  }
  
  handleChange = (e, stations) => {
    const fl = stations.filter(s => {
      let check = false;
      if (s.name) {
        check = s.name.toUpperCase().includes(e.target.value.toUpperCase()) && Object.keys(s.stations).length > 0;
      }
      if (check == false && s.address != null) {
         check = s.address.toUpperCase().includes(e.target.value.toUpperCase()) && Object.keys(s.stations).length > 0;
      }      
      if (check == false && s.stations != null) {
          check = s.stations.some(t => 
            t.chargingUnits.some(c => c.standard.toUpperCase().includes(e.target.value.toUpperCase()))) && Object.keys(s.stations).length > 0;
      }
      return check;
    });   
    this.setState({ filteredList: fl });
  };


  render() {
    const {
      classes,
      stationAreas,
      redirect,
      storeSelectedStation,
      isLoading,
    } = this.props;
    const { filteredList } = this.state;

    return (
      <div className={classes.root}>
        <Grid container >
          <Grid item xs={12} className={classes.container}>
            <div style={{ padding: "0px" }}>
              <SearchFilter
                items={stationAreas}
                handleChange={this.handleChange}
              />
            </div>
          </Grid>
        </Grid>
        {isLoading ? (
          <Grid container>
            <Grid item xs={10} align="center">
              <Spinner />
            </Grid>
          </Grid>
        ) : (
          <List>
            {filteredList &&
              filteredList
              .map((stationArea) => { // enrich with distance
                stationArea.distance = this.getDistance(stationArea.latitude, stationArea.longitude);
                return stationArea;
              })
              .sort((a, b) => (a.distance > b.distance) ? 1 : -1) // sort by distance
              .map(stationArea => (
                <Paper
                  key={stationArea.stationAreaId}
                  className={classes.paper}
                  square={false}
                >
                  <StationsAreaListItem
                    stationArea={stationArea}
                    redirect={redirect}
                    storeSelectedStation={storeSelectedStation}
                  />
                </Paper>
              ))}
          </List>
        )}
      </div>
    );
  }
}

StationsList.propTypes = {
  stationAreas: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object.isRequired
};

StationsList.defaultPropTypes = {
  stationAreas: []
};

export default withStyles(styles)(StationsList);
